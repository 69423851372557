import { db } from "../db";
import { SearchHistoryInterface } from "../interfaces/SearchHistoryInterface";

/**
 * 検索履歴モデル
 */
export class SearchHistory implements SearchHistoryInterface {
  static readonly MAX_SAVE_COUNT = 20;

  id: number;
  path: string;
  conditions: { [title: string]: string };
  createdAt: Date;

  constructor(id: number, path: string, conditions: { [title: string]: string }, createdAt?: Date) {
    this.id = id;
    this.path = path;
    this.conditions = conditions;
    this.createdAt = createdAt;
  }

  /**
   * 検索条件を追加
   *
   * * 検索条件が20件を超えた場合は、20件に収まるように古い履歴を削除する
   */
  static async add(
    path: string,
    conditions: { [title: string]: string },
    createdAt?: Date,
  ): Promise<void> {
    const count = await SearchHistory.getCount();
    if (count >= SearchHistory.MAX_SAVE_COUNT) {
      await this.deleteSearchHistories(count - SearchHistory.MAX_SAVE_COUNT + 1);
    }

    await db.searchHistories.put({
      path: path,
      conditions: conditions,
      createdAt: createdAt || new Date(),
    });
  }

  /**
   * 検索条件を全件取得する
   */
  static async getAll(): Promise<SearchHistory[]> {
    const records = await db.searchHistories.toArray((record) => record);
    return records.map((r) => new SearchHistory(r.id, r.path, r.conditions, r.createdAt));
  }

  /**
   * 検索条件の件数を取得する
   */
  static getCount(): Promise<number> {
    return db.searchHistories.count();
  }

  /**
   * 指定した検索条件を削除する
   */
  public static delete(id: number): Promise<void> {
    return db.searchHistories.delete(id);
  }

  /**
   * 最も新しい検索条件を取得する
   */
  public static async getNewest(): Promise<SearchHistory> {
    const record = await db.searchHistories.orderBy("createdAt").reverse().first();
    return (
      (record && new SearchHistory(record.id, record.path, record.conditions, record.createdAt)) ||
      null
    );
  }

  //
  // 以下は、privateなメソッド
  //

  /**
   * 最も古い検索条件を取得する
   */
  private static async getOldest(): Promise<SearchHistory> {
    const record = await db.searchHistories.orderBy("createdAt").first();
    return new SearchHistory(record.id, record.path, record.conditions, record.createdAt);
  }

  /**
   * countの数だけ古い検索条件を削除する
   */
  private static async deleteSearchHistories(count: number) {
    while (count > 0) {
      count--;
      const lastSearchHistory = await SearchHistory.getOldest();
      await this.delete(lastSearchHistory.id);
    }
  }
}
