"use client";

import { Widget } from "@typeform/embed-react";
import { usePathname, useSearchParams } from "next/navigation";
import React, { useEffect, useState } from "react";

import { SearchHistory } from "~/db/models/SearchHistory";

type Props = {
  campaign: string;
};

const SurveyWidget: React.FunctionComponent<Props> = (props) => {
  const searchParams = useSearchParams();
  const path = usePathname();
  const queryString = searchParams.toString();
  const pathName = queryString ? `${path}?${queryString}` : path;
  const [newestSearchHistory, setNewestSearchHistory] = useState<SearchHistory>(null);

  // 最も新しい検索履歴を取得
  const getNewestSearchHistory = async () => {
    setNewestSearchHistory(await SearchHistory.getNewest());
  };

  const currentPath = queryString ? `${pathName}?${queryString}` : pathName;

  useEffect(() => {
    getNewestSearchHistory();
  }, []);

  return (
    <Widget
      id="uTnmMqVp"
      inlineOnMobile={true}
      hidden={{
        utm_source: `${process.env.NEXT_PUBLIC_FRONT_URL + currentPath}`,
        utm_campaign: props.campaign,
        last_search: newestSearchHistory
          ? process.env.NEXT_PUBLIC_FRONT_URL + newestSearchHistory?.path
          : null,
      }}
    ></Widget>
  );
};

export default SurveyWidget;
