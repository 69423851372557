import Dexie from "dexie";
import { db } from "../db";
import { ViewHistoryInterface } from "../interfaces/ViewHistoryInterface";

/**
 * 閲覧履歴モデル
 */
export class ViewHistory implements ViewHistoryInterface {
  static readonly MAX_HISTORY_COUNT = 50;

  roomId: number;
  createdAt?: Date;

  constructor(roomId: number, createdAt?: Date) {
    this.roomId = roomId;
    this.createdAt = createdAt;
  }

  /**
   * 閲覧履歴を追加
   */
  static async add(roomId: number, createdAt?: Date): Promise<void> {
    if (!roomId) return;

    try {
      await db.viewHistories.put({
        roomId: roomId,
        createdAt: createdAt || new Date(),
      });
    } catch (e) {
      if (e instanceof Dexie.QuotaExceededError) {
        // 容量を超えた場合は古い閲覧履歴を削除する
        await this.deleteViewHistories(10);
        return;
      }
      console.error("Error fetching viewHistories:", e);
    }
  }

  /**
   * 最新の閲覧履歴を取得する
   */
  static async getLatest(limit = 50, excludeIds: number[] = []): Promise<ViewHistory[]> {
    return await db.viewHistories
      .orderBy("createdAt")
      .reverse()
      .filter((viewHistory) => !excludeIds.includes(viewHistory.roomId))
      .limit(limit)
      .toArray();
  }

  /**
   * 閲覧履歴の件数を取得する
   */
  static getCount(): Promise<number> {
    return db.viewHistories.count();
  }

  /**
   * 指定した物件IDの閲覧履歴を取得する
   */
  static async get(roomId: number): Promise<ViewHistory | undefined> {
    return await db.viewHistories.get(roomId);
  }

  //
  // 以下は、privateなメソッド
  //
  /**
   * 指定した閲覧履歴を削除する
   */
  private static delete(roomId: number): Promise<void> {
    return db.viewHistories.delete(roomId);
  }

  /**
   * 最も古い閲覧履歴を取得する
   */
  private static getOldest(): Promise<ViewHistory | undefined> {
    return db.viewHistories.orderBy("createdAt").first();
  }

  /**
   * countの数だけ古い閲覧履歴を削除する
   */
  private static async deleteViewHistories(count: number) {
    while (count > 0) {
      count--;
      const lastViewHistory = await ViewHistory.getOldest();
      if (!lastViewHistory) break;

      await this.delete(lastViewHistory.roomId);
    }
  }
}
